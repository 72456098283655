import React from "react"
import styled from "styled-components"
import { Box, Text } from "../commonElements"
import { rgba } from "polished"
import ResetButton from "./resetButton"

const HighlightText = styled(Text)`
  background-color: ${props => props.theme.colors.accent};
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`

const HighlightHeading = styled(Text)`
  background-color: ${props => props.theme.colors.accent};
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  font-weight: 700;
`

const GameOver = styled(Box)`
  background-color: ${props => rgba(props.theme.colors.backdrop, 0.5)};
  z-index: 100;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  border-radius: 1rem;
  font-size: 1.8rem;
  color: white;
`

const GameOverlay = ({ onRestart, board }) => {
  if (board.hasWon()) {
    return (
      <GameOver alignItems="center" justifyContent="center">
        <HighlightHeading fontSize="3.2rem" textAlign="center">
          Congratulations!
        </HighlightHeading>
        <HighlightText fontSize="2.2rem" textAlign="center">
          You achieve the mission.
        </HighlightText>
        <HighlightText fontSize="2.2rem" textAlign="center">
          Collected a <b>2048 coin</b> with total value of
          <b> ${(board.score / 100.0).toFixed(2)}</b>.
        </HighlightText>
        <HighlightText fontSize="2.2rem" textAlign="center">
          Excellent! 👍
        </HighlightText>
        <ResetButton reset={onRestart} mt="3.5rem">
          Play Again
        </ResetButton>
      </GameOver>
    )
  } else if (board.hasLost()) {
    // } else {
    return (
      <GameOver alignItems="center" justifyContent="center">
        <HighlightHeading fontSize="3.5rem" textAlign="center">
          Game Over
        </HighlightHeading>
        <HighlightText fontSize="2.2rem" textAlign="center">
          You collected <b>${(board.score / 100.0).toFixed(2)}</b>. Great Job!
          👍
        </HighlightText>
        <HighlightText fontSize="2.2rem" textAlign="center">
          Continue to collect more.
        </HighlightText>
        <ResetButton reset={onRestart} mt="5rem">
          Try Again
        </ResetButton>
      </GameOver>
    )
  }
  return null
}

export default GameOverlay
