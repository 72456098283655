import {
  UPDATE_2048_SCORES,
} from "./constants"

export const update2048Scores = (previousScore, accumScore) => ({
  type: UPDATE_2048_SCORES,
  payload: {
    previousScore,
    accumScore,
  },
})