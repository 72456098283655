import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { Button } from "../commonElements"
import styled from "styled-components"

const RButton = styled(Button)`
  background: linear-gradient(
    90deg,
    ${props => props.toolColors[0]} 0%,
    ${props => props.toolColors[1]} 100%
  );
  color: white;
  cursor: pointer;
  font-size: 2rem;
  padding: 1rem 2rem;
  border-radius: 7px;
  @media ${props => props.theme.device.mobile} {
    padding: 0.7rem 1.5rem;
    font-size: 1.8rem;
  }
`

const ResetButton = ({ reset, children, ...props }) => {
  const toolColorsPersist = useSelector(state => state.settings.colors.array)
  const [toolColors, setToolColors] = useState(["#3D3D3D", "#3D3D3D"])

  useEffect(() => {
    if (toolColorsPersist !== undefined) {
      //there is a chance toolColorsPersist undefined, redux persist make 2 level merge
      setToolColors(toolColorsPersist)
    } else {
      setToolColors(["#59569D", "#F25292"])
    }
  }, [toolColorsPersist])

  return (
    <RButton onClick={reset} {...props} toolColors={toolColors}>
      {children}
    </RButton>
  )
}

export default ResetButton
