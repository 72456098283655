import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { RedoAlt } from "@styled-icons/fa-solid"
import Cell from "./cell.js"
import Tile from "./tile.js"
import { Board } from "../../utils/2048Algo.js"
import styled from "styled-components"
import useEvent from "../hook/useEvent"
import EndingOverlay from "./endingOverlay.js"
import { Box, GlassBox, IconButton } from "../commonElements"
import { darken } from "polished"
import ResetButton from "./resetButton.js"
import { useSwipeable, UP, DOWN, RIGHT, LEFT } from "react-swipeable"
import { update2048Scores } from "../../redux/coin2048/actions.js"
import ScoreSound from "../../audio/2048-score.mp3"
import GameWinSound from "../../audio/2048-win.mp3"
import GameOverSound from "../../audio/2048-game-over.mp3"
import { Howl } from "howler"

const DetailsBox = styled(Box)``

const LeftBox = styled(Box)``
const RightBox = styled(Box)`
  justify-content: flex-end;
`

const ScoreBox = styled(Box)`
  /* background-color: white;
  padding: 0.5rem 1.5rem;
  border-radius: 7px; */
  justify-content: center;
  align-items: flex-start;
  font-size: 2.2rem;
  @media ${props => props.theme.device.mobile} {
    font-size: 1.8rem;
  }
  @media (max-width: 360px) {
    font-size: 1.6rem;
  }
`

// const TitleBox = styled(Box)`
//   font-size: 6rem;
//   color: white;
//   text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
//   font-weight: 700;
//   line-height: 6rem;
// `

// const DescriptionBox = styled(Box)`
//   font-size: 2rem;
// `

const BoardBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  order: 1;
  max-width: 57rem;
  width: 100%;
  height: 57rem;
  padding: 5px;
  /* border: 5px solid ${props => darken(0.15, props.theme.colors.primary)}; */

  border-radius: 7px;
  outline: none;
  position: relative;
  font-size: 55px;
  @media (max-width: 621px) {
    height: 55rem;
    max-width: 55rem;
    font-size: 50px;
  }
  @media (max-width: 571px) {
    height: 50rem;
    max-width: 50rem;
    font-size: 45px;
  }
  @media (max-width: 521px) {
    height: 45rem;
    max-width: 45rem;
    font-size: 40px;
  }
  @media (max-width: 471px) {
    height: 39rem;
    max-width: 39rem;
    font-size: 34px;
  }
  @media (max-width: 411px) {
    height: 36rem;
    max-width: 36rem;
    font-size: 30px;
  }
  @media (max-width: 381px) {
    height: 33rem;
    max-width: 33rem;
    font-size: 28px;
  }
  @media (max-width: 351px) {
    height: 29rem;
    max-width: 29rem;
    font-size: 24px;
  }
  /*minimum support screen width 311px */
`

const BoardView = () => {
  const [board, setBoard] = useState(new Board())
  const { previousScore, accumScore } = useSelector(state => state.coin2048)
  const toolColorsPersist = useSelector(state => state.settings.colors.array)
  const [cells, setCells] = useState(null)
  const [tiles, setTiles] = useState(null)
  const soundOn = useSelector(state => state.general.sound.on)
  const [scoreSound, setScoreSound] = useState(null)
  const [gameWinSound, setGameWinSound] = useState(null)
  const [gameOverSound, setGameOverSound] = useState(null)
  // let tiles
  const dispatch = useDispatch()

  useEffect(() => {
    setScoreSound(
      new Howl({
        src: [ScoreSound],
      })
    )
    setGameWinSound(
      new Howl({
        src: [GameWinSound],
      })
    )
    setGameOverSound(
      new Howl({
        src: [GameOverSound],
      })
    )
  }, [])

  const handleScoreSound = () => {
    if (soundOn) {
      scoreSound.volume(0.2)
      scoreSound.play()
    }
  }

  const handleGameWinSound = () => {
    if (soundOn) {
      gameWinSound.volume(0.4)
      gameWinSound.play()
    }
  }

  const handleGameOverSound = () => {
    if (soundOn) {
      gameOverSound.volume(0.3)
      gameOverSound.play()
    }
  }

  useEffect(() => {
    setCells(renderCells(toolColorsPersist))
  }, [toolColorsPersist])

  useEffect(() => {
    setTiles(renderTiles(board))
  }, [])

  useEffect(() => {
    if (board.score === 0) return
    const diff = board.score - previousScore
    if (diff > 0) {
      dispatch(update2048Scores(board.score, accumScore + diff))
    }
    if (board.hasWon()) {
      handleGameWinSound()
    } else if (board.hasLost()) {
      handleGameOverSound()
    } else if (diff > 0) {
      handleScoreSound()
    }
  }, [board.score])

  const handleKeyDown = event => {
    let keyCode = event.keyCode
    //change AWDS keys to arrow keys
    if (keyCode === 65) {
      keyCode = 37
    } else if (keyCode === 87) {
      keyCode = 38
    } else if (keyCode === 68) {
      keyCode = 39
    } else if (keyCode === 83) {
      keyCode = 40
    }
    if (keyCode >= 37 && keyCode <= 40) {
      event.preventDefault()
      if (board.hasWon()) {
        return
      }
      let direction = keyCode - 37
      let boardClone = Object.assign(
        Object.create(Object.getPrototypeOf(board)),
        board
      )
      let newBoard = boardClone.move(direction)
      setBoard(newBoard)
      setTiles(renderTiles(newBoard))
    }
  }

  const handleSwiped = eventData => {
    // 0 -> left, 1 -> up, 2 -> right, 3 -> down
    let direction = 0
    switch (eventData.dir) {
      case LEFT:
        direction = 0
        break
      case UP:
        direction = 1
        break
      case RIGHT:
        direction = 2
        break
      case DOWN:
        direction = 3
        break
    }
    let boardClone = Object.assign(
      Object.create(Object.getPrototypeOf(board)),
      board
    )
    let newBoard = boardClone.move(direction)
    setBoard(newBoard)
    setTiles(renderTiles(newBoard))
  }

  const handlers = useSwipeable({
    onSwiped: handleSwiped,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  })

  useEvent("keydown", handleKeyDown)

  // tiles = board.tiles
  //   .filter(tile => tile.value !== 0)
  //   .map((tile, index) => {
  //     return <Tile tile={tile} key={`tile-pos${index}`} />
  //   })

  const resetGame = () => {
    dispatch(update2048Scores(0, accumScore))
    const newBoard = new Board()
    setBoard(newBoard)
    setTiles(renderTiles(newBoard))
  }

  const renderCells = toolColors => {
    return board.cells.map((row, rowIndex) => {
      return (
        <Box
          width="100%"
          height="calc(100% / 4)"
          flexDirection="row"
          justifyContent="center"
          key={`cell-row-${rowIndex}`}
        >
          {row.map((col, colIndex) => {
            return (
              <Cell
                key={`cell-row-column-${rowIndex}-${colIndex}`}
                bgColor={toolColors[0]}
              />
            )
          })}
        </Box>
      )
    })
  }

  const renderTiles = currentBoard => {
    return currentBoard.tiles
      .filter(tile => tile.value !== 0)
      .map((tile, index) => {
        return <Tile tile={tile} key={`tile-pos${index}`} />
      })
  }

  return (
    <Box alignItems="stretch" width="100%">
      <GlassBox alignItems="center">
        <BoardBox {...handlers}>
          {cells}
          {tiles}
          <EndingOverlay onRestart={resetGame} board={board} />
        </BoardBox>
      </GlassBox>
      <GlassBox alignItems="center" mt="1rem">
        <DetailsBox
          justifyContent="space-between"
          flexDirection="row"
          padding="1rem"
          width="60rem"
          alignItems="center"
          gap="0.5rem"
        >
          <LeftBox gap=".7rem" alignItems="flex-start" justifyContent="center">
            {/* <TitleBox>FS 2048</TitleBox>
          <DescriptionBox>Stack up your coins 🪙</DescriptionBox> */}
            <ScoreBox>
              <span>
                Coins ($): <strong>{(board.score / 100.0).toFixed(2)}</strong>
              </span>
              <Box flexDirection="row" alignItems="center" gap=".5rem">
                <span>
                  Gathered ($):{" "}
                  <strong>{(accumScore / 100.0).toFixed(2)}</strong>
                </span>
                <IconButton
                  icon={<RedoAlt size={16} />}
                  onClick={() => {
                    dispatch(update2048Scores(board.score, 0))
                  }}
                />
              </Box>
            </ScoreBox>
          </LeftBox>
          <RightBox gap=".8rem">
            <ResetButton reset={resetGame}>New Game</ResetButton>
          </RightBox>
        </DetailsBox>
      </GlassBox>
    </Box>
  )
}

export default BoardView
