import React from "react"

import styled, { keyframes, css } from "styled-components"
import { darken, lighten, rgba } from "polished"
import "./animation.css"

const movingTileRow = movingObj => keyframes`
  0% {
    top: ${movingObj.fromTop}px;
  }
  100% {
    top: ${movingObj.toTop}px;
  }
`

const movingTileColumn = movingObj => keyframes`
  0% {
    left: ${movingObj.fromLeft}px;
  }
  100% {
    left: ${movingObj.toLeft}px;
  }
`

const newTile = keyframes`
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
`

const createCSS = () => {
  let styles = ""

  for (let row = 0; row < 4; row++) {
    for (let column = 0; column < 4; column++) {
      // const top = 110 * row + 5
      // const left = 110 * column + 5
      const top = `calc((100% - 1rem) / 4 * ${row} + 0.5rem)`
      const left = `calc((100% - 1rem) / 4 * ${column} + 0.5rem)`
      styles += `
      &.position_${row}_${column}:not(.isMoving) {
        top: ${top};
        left: ${left};
      }
      `
    }
  }

  for (let fromRow = 0; fromRow < 4; fromRow++) {
    for (let toRow = 0; toRow < 4; toRow++) {
      const name = `row_from_${fromRow}_to_${toRow}`
      if (fromRow === toRow) {
        // const top = 110 * toRow + 5
        const top = `calc((100% - 1rem) / 4 * ${toRow} + 0.5rem)`
        styles += `
          &.${name} {
            top: ${top};
          }
        `
      } else {
        // const fromRow = 110 * fromRow + 5
        // const toRow = 110 * toRow + 5
        styles += `
          &.${name} {
            animation-duration: 0.2s;
            animation-name: ${name};
            animation-fill-mode: forwards;
          }
        `
      }
    }
  }

  for (let fromColumn = 0; fromColumn < 4; fromColumn++) {
    for (let toColumn = 0; toColumn < 4; toColumn++) {
      const name = `column_from_${fromColumn}_to_${toColumn}`
      if (fromColumn === toColumn) {
        // const left = 110 * toColumn + 5
        const left = `calc((100% - 1rem) / 4 * ${toColumn} + 0.5rem)`
        styles += `
          &.${name} {
            left: ${left};
          }
        `
      } else {
        // const fromColumn = 110 * fromColumn + 5
        // const toColumn = 110 * toColumn + 5
        styles += `
          &.${name} {
            animation-duration: 0.2s;
            animation-name: ${name};
            animation-fill-mode: forwards;
          }
        `
      }
    }
  }
  // console.log(styles)

  return css`
    ${styles}
  `
}

// const movingTileColumnStyle = css`
//   animation-duration: 0.2s;
//   animation-name: ${movingTileColumn};
//   animation-fill-mode: forwards;
// `

// const movingTileRowStyle = css`
//   animation-duration: 0.2s;
//   animation-name: ${movingTileRow};
//   animation-fill-mode: forwards;
// `

// const newTileStyle = css`
//   animation-duration: 0.2s;
//   animation-name: ${newTile};
//   animation-fill-mode: forwards;
//   animation-delay: 0.15s;
//   transform: scale(0);
// `

// const positionTile = css`
//   &:not(.isMoving) {
//     top: ${props => `${props.position.top}px`};
//     left: ${props => `${props.position.left}px`};
//   }
// `

const DollarSign = styled.span`
  font-size: 33%;
  line-height: 150%;
`

const Dollar = styled.span`
  &.tile256 {
    font-size: 95%;
  }

  &.tile512 {
    font-size: 95%;
  }

  &.tile1024 {
    font-size: 80%;
  }

  &.tile2048 {
    font-size: 80%;
  }
`

const CoinBorder = styled.div`
  top: 4%;
  left: 4%;
  width: 92%;
  height: 92%;
  position: absolute;
  border-radius: 50%;
  /* border: 2px solid ${props => props.theme.colors.borderCoin}; */
  background-color: transparent;
  border-width: 2px;
  border-style: solid;
  border-color: ${props => rgba("#fff", 0.5)};
`

const Content = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  font-weight: bold;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  font-size: 100%;
  line-height: 100%;
  border-radius: 50%;
  /* font-family: "Clear Sans"; */
  color: #766;
  background-color: #3d2963;
  user-select: none;
  cursor: default;
  padding-top: 10%;
  &.tile0 {
    background-color: #dcb;
  }

  &.tile2 {
    color: ${props => darken(0.25, "#ca9d98")};
    background-color: #ca9d98;
  }

  &.tile4 {
    color: ${props => lighten(0.4, "#ad6f69")};
    background-color: #ad6f69;
  }

  &.tile8 {
    color: ${props => darken(0.27, "#a4adad")};
    background-color: #a4adad;
  }

  &.tile16 {
    color: ${props => lighten(0.45, "#818885")};
    background-color: #818885;
  }

  &.tile32 {
    color: ${props => darken(0.25, "#e0bb49")};
    background-color: #e0bb49;
  }

  &.tile64 {
    color: ${props => lighten(0.45, "#c8a420")};
    background-color: #c8a420;
  }

  &.tile128 {
    /* color: #ffe; */
    color: ${props => lighten(0.55, "#bc9800")};
    background-color: #bc9800;
  }

  &.tile256 {
    color: ${props => lighten(0.6, "#b18d00")};
    background-color: #b18d00;
  }

  &.tile512 {
    color: ${props => lighten(0.6, "#a39726")};
    background-color: #a39726;
  }

  &.tile1024 {
    color: ${props => lighten(0.65, "#988d07")};
    background-color: #988d07;
  }

  &.tile2048 {
    color: ${props => lighten(0.65, "#6a6325")};
    background-color: #6a6325;
  }
`

// const PaperContent = styled.div`
//   position: relative;
//   display: flex;
//   width: 88%;
//   height: 55%;
//   font-weight: bold;
//   align-items: center;
//   justify-content: center;
//   flex-direction: column;
//   font-size: 20px;
//   font-family: "Clear Sans";
//   color: #766;
//   background-color: #3d2963;
//   margin: auto;
//   margin-top: 28px;
//   user-select: none;
//   cursor: default;
//   &.tile1024 {
//     color: ${props => lighten(0.65, "#988d07")};
//     background-color: #859d72;
//     /* background-image: ${_ => `url(${image1024})`}; */
//     font-size: 35px;
//   }
// `

// const TileContent = ({ value }) => {
//   return <div></div>
// }

const Tile = styled.div`
  position: absolute;
  width: calc((100% - 5rem) / 4);
  height: calc((100% - 5rem) / 4);
  margin: 0.5rem;
  padding: 1%;
  display: inline-block;

  ${createCSS()};

  &.merged {
    display: none;
    &.isMoving {
      display: inline;
    }
  }

  /* &.moveRow {
    ${props =>
    props.movingObj.fromTop === undefined
      ? css`
          top: ${props => `${props.position.top}px`}; //not moving
        `
      : css`
          animation-duration: 0.2s;
          animation-name: ${props => movingTileRow(props.movingObj)};
          animation-fill-mode: forwards;
        `}
  }

  &.moveColumn {
    ${props =>
    props.movingObj.fromLeft === undefined
      ? css`
          left: ${props => `${props.position.left}px`}; //not moving
        `
      : css`
          animation-duration: 0.2s;
          animation-name: ${props => movingTileColumn(props.movingObj)};
          animation-fill-mode: forwards;
        `}
  } */

  &.new {
    animation-duration: 0.2s;
    animation-name: ${newTile};
    animation-fill-mode: forwards;
    animation-delay: 0.15s;
    transform: scale(0);
  }
`

const TileView = ({ tile }) => {
  // console.log(tile)

  const tileContentClass = "tile" + tile.value
  let classArray = []
  let position = {}
  let movingObj = {}
  // let isMoving = false
  if (!tile.mergedInto) {
    position.top = 110 * tile.row + 5
    position.left = 110 * tile.column + 5
    classArray.push(`position_${tile.row}_${tile.column}`)
  } else {
    classArray.push("merged")
  }

  if (tile.isNew()) {
    classArray.push("new")
  }
  if (tile.hasMoved()) {
    classArray.push(`row_from_${tile.fromRow()}_to_${tile.toRow()}`)
    classArray.push(`column_from_${tile.fromColumn()}_to_${tile.toColumn()}`)
    // classArray.push("moveRow")
    // if (tile.fromRow() === tile.toRow()) {
    //   position.top = 110 * tile.toRow() + 5
    // } else {
    //   movingObj.fromTop = 110 * tile.fromRow() + 5
    //   movingObj.toTop = 110 * tile.toRow() + 5
    // }

    // classArray.push("moveColumn")
    // if (tile.fromColumn() === tile.toColumn()) {
    //   position.left = 110 * tile.toColumn() + 5
    // } else {
    //   movingObj.fromLeft = 110 * tile.fromColumn() + 5
    //   movingObj.toLeft = 110 * tile.toColumn() + 5
    // }
    classArray.push("isMoving")
  }

  const classes = classArray.join(" ")
  // console.log(classes, position)

  return (
    <Tile className={classes} movingObj={movingObj} position={position}>
      <Content className={tileContentClass}>
        <CoinBorder />
        <DollarSign>¢</DollarSign>
        <Dollar className={tileContentClass}>{tile.value}</Dollar>
      </Content>
    </Tile>
  )
}

export default TileView

{
  /* {tile.value !== 1024 ? ( */
}
// ) : (
//   <PaperContent className={tileContentClass}>
//     <div
//       style={{
//         backgroundColor: "#acbc27",
//         borderRadius: "40px",
//         paddingLeft: "10px",
//         paddingRight: "10px",
//         fontSize: "30px",
//       }}
//     >
//       ¢1024
//     </div>
//   </PaperContent>
// )}
