import React from "react"
import styled from "styled-components"
import { darken } from "polished"

const Cell = styled.div`
  width: calc(100% / 4);
  height: calc(100% - 1rem);
  margin: 5px;
  line-height: 90px;
  display: inline-block;
  font-size: 55px;
  font-weight: bold;
  text-align: center;
  vertical-align: middle;
  border-radius: 50%;
  /* font-family: "Clear Sans"; */
  color: #766;
  background-color: ${props => darken(0.15, props.bgColor)};
  user-select: none;
  cursor: default;
  border: 2px solid #000;
  -webkit-box-shadow: inset 0px 0px 5px #000;
  -moz-box-shadow: inset 0px 0px 5px #000;
  box-shadow: inset 0px 0px 5px #000;
`

const CellView = ({ bgColor }) => {
  return <Cell bgColor={bgColor}></Cell>
}

export default CellView
